import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import { handleSortTableDataDivWrapProperyArray } from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import {TablePanelInfo} from "../../../../Components/Headings/TableHeading/TablePanelInfo"
import { Link } from "react-router-dom";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { appContext } from "../../../../AppContext";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";

class ListFiles extends Component<any, IState>
{
    private labels = appContext().labels;
    private get appState() { return appContext().state; }

    constructor(props: any)
    {
        super(props);
        this.state = {
            isLoading: false,
            show: false,
            fileList: [],
            facilityDataSort: { columnName: "", direction: "" },
            addModal: false,
            panelInfo: { heading: "", iconsrc: "" },
            pathValue: "",
            modalPathValue: "",
            modalSelectFileValue: "",
            selectedFileName: "",
            modalCheckboxValue: false,
            refreshNodesSelected: false
        };
    }

    componentDidMount()
    {
        const { history, match }: any = this.props;
        let url = window.location.href;
        let pathName = "";
        let iconsrc = "";
        let heading = "";

        if (url.includes('roamer'))
        {
            pathName = "r";
            heading = this.labels.HubMenuRoamer;
            iconsrc = "Mobile";
        } else if (url.includes('unity'))
        {
            pathName = "u";
            heading = this.labels.HubMenuUnity
            iconsrc = "Tablet"
        } else if (url.includes('inspire'))
        {
            pathName = "i";
            heading = this.labels.HubMenuInspire
            iconsrc = "Kisko"
        } else if (url.includes('common'))
        {
            pathName = "c";
            heading = this.labels.HubMenuCommon
            iconsrc = "MobileDevices"
        } else
        {

        }
        this.setState({
            pathValue: pathName,
            panelInfo: { heading: heading, iconsrc: iconsrc },
            modalPathValue: `${pathName}/`
        })
        this.handleGetRoamerPageData(pathName)


    }

    handleGetRoamerPageData = async (pathName: any) =>
    {
        let url = window.location.href;
        let response: any;
        this.setState({ isLoading: true })

        try
        {
            response = await apis.getRoamerPageData(pathName)
            const responseData = response.data
            const newResponseData = responseData && responseData[0] && responseData.map((item: any) =>
            {
                let newItem = { ...item }
                newItem.isChecked = false
                newItem.path = (`${item.path}/${item.name}`)
                newItem.table_lastModified = moment(item.lastModified).format("DD/MM/yyyy HH:mm")
                return newItem
            })

            this.setState({ fileList: newResponseData ? newResponseData : [] })
        }
        catch (error: any)
        {
        }

        this.setState({ isLoading: false })
        return;
    }

    handleClickCheckBox = (e: any, checkItem: any) =>
    {
        const newList = this.state.fileList.map((item: any) =>
        {
            let newItem = { ...item }
            if (checkItem.name === item.name)
            {
                newItem.isChecked = !item.isChecked
            } else
            {
                newItem.isChecked = false
            }
            return newItem
        })
        this.setState({ fileList: newList })

    }

    handleTableDataSort = (e: any) =>
    {
        this.setState({ facilityDataSort: { columnName: e.column, direction: e.direction }, })
        if (e.column === "table_lastModified")
        {
            const sortedArray = _.orderBy(this.state.fileList, (item: any) => item.lastModified, e.direction);
            this.setState({ fileList: sortedArray })
        }
        else
        {
            const sortedArray = _.orderBy(this.state.fileList, (item: any) => item[e.column], e.direction);
            this.setState({ fileList: sortedArray })
        }
    }

    checkIsButtonDisabled = () =>
    {
        let checkedCount = 0
        this.state.fileList.forEach((item: any) =>
        {
            if (item.isChecked === true)
            {
                checkedCount++
            }
        });

        const buttonDisabledObject = {
            isDownloadButtonDisabled: checkedCount == 1 ? false : true,
            isDeleteButtonDisabled: checkedCount == 1 ? false : true,
        }

        return buttonDisabledObject;
    }

    isDeleteFile = () =>
    {
        this.setState({
            show: !this.state.show,
        })
        this.deleteParticularFile();
    }

    cancelDeleteModal = () =>
    {
        this.setState({
            show: !this.state.show,
        })
    }

    cancelModal = () =>
    {
        this.setState({
            addModal: false,
            modalCheckboxValue: false,
            refreshNodesSelected: false,
            modalSelectFileValue: "",
            selectedFileName: "",
            modalPathValue: `${this.state.pathValue}/`
        })
    }

    deleteParticularFile = () =>
    {
        const { history, match }: any = this.props;
        let url = window.location.href;
        let response: any;
        if (this.state.show)
        {
            this.state.fileList.forEach((item: any) =>
            {
                if (item.isChecked === true)
                {
                    try
                    {
                        response = apis.deleteFilePath(item.path)
                        const responseData = response.data
                        this.cancelDeleteModal();
                        this.handleGetRoamerPageData(this.state.pathValue)
                        return responseData
                    }
                    catch (error: any)
                    {
                    }

                    this.setState({ isLoading: false })
                    return;
                }
            });
        }

    }

    handleDownloadFile = () =>
    {
        let fullPath: any = "";
        let response: any;
        this.setState({ isLoading: true })
        this.state.fileList.forEach((item: any) =>
        {
            if (item.isChecked === true)
            {
                try
                {
                    response = apis.downloadImageFile(item.path)
                    const responseData = response.data
                    this.setState({ isLoading: false })
                    return responseData
                }
                catch (error: any)
                {
                }
                return;
            }

        })
    }

    handleAddButtonModal = () =>
    {
        this.setState({ addModal: true })
    }
    handleClose = () =>
    {
        this.setState({ addModal: false })
    }

    changeFileHandler = (event: any) =>
    {
        this.setState({
            modalSelectFileValue: event.target.files[0],
            selectedFileName: event.target.files[0].name,
            modalPathValue: `${this.state.pathValue}/${event.target.files[0].name}`
        });
    };

    changePathHandler = (e: any) =>
    {
        // @ts-ignore
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCheckBox = () =>
    {
        this.setState({ modalCheckboxValue: !this.state.modalCheckboxValue })
    }

    handleModalOkButton = async () =>
    {
        this.setState({ isLoading: true })
        try
        {
            const formData = new FormData();
            formData.append("file", this.state.modalSelectFileValue);
            formData.append("fileName", this.state.selectedFileName);
            const fileDetail = {
                fileData: formData,
                modalPathValue: this.state.modalPathValue,
                processAsMapvalue: this.state.modalCheckboxValue,
            }
            const response: any = await apis.uploadFile(fileDetail)
            const responseData = response.data
            this.cancelModal()
            await this.handleGetRoamerPageData(this.state.pathValue)
            if (this.state.refreshNodesSelected && this.state.modalCheckboxValue)
            {
                await appContext().ibssApiClientV1.v1.service.refreshCache.get({
                    type: 'Nodes',
                    nodeId: this.appState.buildingId
                });
            }
        }
        catch (error: any)
        {
        }
        this.setState({ isLoading: false })
        return;
    }

    render()
    {


        const { isDownloadButtonDisabled, isDeleteButtonDisabled, }: any = this.checkIsButtonDisabled()

        const confModalData = {
            show: this.state.show,
            handleModal: this.cancelDeleteModal,
            okButton: this.deleteParticularFile,
            modalHeading: this.labels.HubLabelConfirmation,
            modalMessage: this.labels.HubLabelDeleteMessage,
        }

        let newFileList: any = []

        this.state.fileList && this.state.fileList[0] && this.state.fileList.forEach((item: any, index: any) =>
        {
            let newItem = { ...item }
            newItem.check = <label className="newCheckBox">
                <input type="checkbox" className="checkBoxHide" name={item.name} id={item.name} checked={item.isChecked} onChange={(e: any) => { this.handleClickCheckBox(e, item) }} />
                <span className="geekmark"></span>
            </label>
            newFileList.push(newItem)
        })

        const roamerData = {
            columns: [
                {
                    label: "",
                    field: "check",
                    sort: "disabled",
                },
                {
                    label: this.labels.HubLabelName,
                    field: "path",
                    width: 50,
                    sort: this.state.facilityDataSort.columnName == "path" ? this.state.facilityDataSort.direction : "",

                },
                {
                    label: this.labels.HubLabelDate,
                    field: "table_lastModified",
                    width: 50,
                    sort: this.state.facilityDataSort.columnName == "table_lastModified" ? this.state.facilityDataSort.direction : "",

                },
                {
                    label: this.labels.HubLabelSizeText,
                    field: "size",
                    width: 50,
                    sort: this.state.facilityDataSort.columnName == "size" ? this.state.facilityDataSort.direction : "",

                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                },
            ],
            rows: newFileList ? newFileList : []
        };



        return (
            <>
                <div className="page-container" style={{overflow:"hidden",display:"flex",backgroundColor:"var(--ui-background)"}}>
                    <IbssDialog
                        open={this.state.addModal}
                        onClose={() => this.cancelModal()}
                        header="Upload File"
                        dialogContent=
                        {
                            <>
                                <div className="row mb-3">
                                    <label className="upload-file-modal-label col-4 pl-0 pr-0">Path</label>
                                    <input className="upload-file-modal-input col-8 pl-0 pr-0" type="text" name="modalPathValue" value={this.state.modalPathValue} onChange={this.changePathHandler}></input>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-4 pl-0 ">
                                        <label className="upload-file-modal-label pr-0">File</label>
                                    </div>
                                    <div className="col-8 pl-0">
                                        <input className="upload-file-modal-input" value={this.state.selectedFileName} type="text" readOnly></input>
                                        <label htmlFor="filePicker" className="select  button-outline file-picker-label mr-1">Select File</label>
                                        <input id="filePicker" className="hideInput" type={"file"} onChange={this.changeFileHandler}></input>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="upload-file-modal-label col-4 pl-0 pr-0">Process as Map</label>
                                    <input className="col-8 upload-file-modal-input pl-0 pr-0" type="checkbox" name="modalCheckboxValue" defaultChecked={this.state.modalCheckboxValue} onChange={this.handleCheckBox}></input>
                                </div>
                                {
                                    this.state.modalCheckboxValue &&
                                        <div className="row mt-3">
                                            <label className="upload-file-modal-label col-4 pl-0 pr-0">{this.labels.funcRefreshNodesCache_S}</label>
                                            <input className="col-8 upload-file-modal-input pl-0 pr-0" type="checkbox" name="modalCheckboxValue" defaultChecked={this.state.refreshNodesSelected} onChange={() => this.setState({ refreshNodesSelected: !this.state.refreshNodesSelected})}></input>
                                        </div>
                                }
                            </>
                        }
                        fullWidth
                        footer=
                        {
                            <>
                                <IbssButtonRedo color="secondary" variant="contained"
                                    className="mr-2"
                                    onClick={this.cancelModal}
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo color="primary" variant="contained"
                                    className="ml-2"
                                    onClick={this.handleModalOkButton}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    {this.state.show ? (
                        <ConfirmModal {...confModalData} />
                    ) : ""}
                    <div className="rightPanel-main-content">
                        <div className="table-panel">
                            <TablePanelInfo
                                tableHeading={this.state.panelInfo.heading}
                                iconSrc={this.state.panelInfo.iconsrc}
                            />
                            <div className="table-panel-content">
                                <button className="button-primary" onClick={this.handleAddButtonModal}>{this.labels.HubButtonAdd}</button>
                            </div>
                            <div className="position-relative">
                                {(this.state.isLoading || this.state.isLoading) && <Spinner />}
                                <MDBDataTable className="standardTable table-noSubmenue-withBottomButton tableFiles" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small onSort={this.handleTableDataSort} data={roamerData} />
                            </div>
                        </div>
                        <div className="main-button-content">
                            <div className="buttons-group" >
                                <div className="col-12 div-flex">
                                    <div className="col-12">
                                        <button className="button-secondary mr-2" disabled={isDeleteButtonDisabled} onClick={() => this.isDeleteFile()}>{this.labels.HubButtonDelete}</button>
                                    </div>
                                    <div className="space-between">
                                        <button className="button-secondary mr-2" disabled={isDownloadButtonDisabled} onClick={() => this.handleDownloadFile()}>{this.labels.HubButtonDownload}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        lightModeTheme: state.lightModeTheme,
        // mainPageTitle: state.mainPageTitle,
    };
};

export default connect(mapStateToProps)(ListFiles);

export interface IState
{
    isLoading: boolean;
    show: boolean;
    fileList: any[];
    facilityDataSort: { columnName: string, direction: string };
    addModal: boolean;
    panelInfo: { heading: string, iconsrc: string };
    pathValue: string;
    modalPathValue: string;
    modalSelectFileValue: string;
    selectedFileName: string;
    modalCheckboxValue: boolean;
    refreshNodesSelected: boolean;
}
