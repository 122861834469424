import { WeekdayNumbers } from "luxon";
import { IBookableSlot } from "./BookableSpaces";

export class BookableSlotAdapter implements IBookableSlot
{
    public bookingPolicyId = '';
    public dayOfWeek: WeekdayNumbers = 1;
    public startTimeOfDay = '';
    public endTimeOfDay = '';

    public static fromBookingPolicies(bookingPolicies: IBookingPolicy[]): BookableSlotAdapter[]
    {
        let bookableSlots = bookingPolicies.flatMap(policy =>
            policy.Booking_Policy.BookingSlots.BookableTime.flatMap(slot =>
                slot.Days.map(day => ({ bookingPolicyId: policy.Booking_Policy_Id, dayOfWeek: parseInt(day.toString()) as WeekdayNumbers, startTimeOfDay: slot.StartTime, endTimeOfDay: slot.EndTime }))));

        // have seen bad data so sanitise
        bookableSlots = bookableSlots.filter(i => !isNaN(i.dayOfWeek) && i.startTimeOfDay.match(/^\d\d:\d\d$/) && i.endTimeOfDay.match(/^\d\d:\d\d$/));

        // normalise 23:59 end time to 24:00
        bookableSlots.filter(i => i.endTimeOfDay == '23:59').forEach(i => i.endTimeOfDay = '24:00');

        return bookableSlots;
    }
}

export interface IBookingPolicy
{
    Booking_Policy_Id: string;
    Booking_Policy: IBookingPolicyObject;
}

export interface IBookingPolicyObject 
{
    BookingSlots: IBookingSlots;
}

export interface IBookingSlots
{
    BookableTime: IBookableTime[],
}

export interface IBookableTime
{
    Days: number[];
    StartTime: string;
    EndTime: string;
}
