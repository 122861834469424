import { DateTime, Duration, WeekdayNumbers } from "luxon";
import { DateHelper } from "../../../Common/DateHelper";
import { appContext } from "../../../AppContext";
import { INode } from "../../../Providers.Api/Models";
import { NodeHelper } from "../../../Common/NodeHelper";

export class BookableSpaces
{
    private bookableSlots: IBookableSlot[];
    private nodes = new NodeHelper();
    private cache = new Map<string, boolean>();

    constructor(bookableSlots: IBookableSlot[])
    {
        this.bookableSlots = bookableSlots;
    }

    public canBook(space: IBookableSpace, from: DateTime, to: DateTime): boolean
    {
        const key = `${space.spaceId}/${from.toISO()}/${to.toISO()}`;
        if (!this.cache.has(key))
        {
            const canBook = this.calculateCanBook(space, from, to);
            this.cache.set(key, canBook);
        }
        return this.cache.get(key) ?? false;
    }

    private calculateCanBook(space: IBookableSpace, from: DateTime, to: DateTime): boolean
    {
        const building = this.nodes.getBuildingByNode(space.nodeId);
        if (!building)
        {
            return false;
        }

        // ensure the booking is in the future
        const now = DateHelper.now(building.Node_Id);
        if (now > from)
        {
            return false;
        }

        // ensure the booking is within the bookable time-range
        let start = this.setTime(from, building.Occ_Wkng_Hrs_Stt);
        let end = this.setTime(from, building.Occ_Wkng_Hrs_Stp);

        const canBookOutsideWorkingHours = appContext().localStorageProvider.hasRight('API.Bookings.BookOutsideWorkingHours', building.Node_Id);
        if (canBookOutsideWorkingHours)
        {
            start = this.setTime(from, building.Occ_Office_hrs_stt);
            end = this.setTime(from, building.Occ_Office_hrs_stp);
        }

        const canBookOutsideOfficeHours = appContext().localStorageProvider.hasRight('API.Bookings.BookOutsideOfficeHours', building.Node_Id);
        if (canBookOutsideOfficeHours)
        {
            start = this.setTime(from, '00:00:00');
            end = this.setTime(from, '24:00:00');
        }

        if (start > from || end < to)
        {
            return false;
        }

        // ensure the booking is within a booking slot
        const bookableSlotsForSpace = this.bookableSlots.filter(i => i.bookingPolicyId == space.bookingPolicyId);
        for (const bookableSlot of bookableSlotsForSpace)
        {
            const slotStart = this.setTime(from, bookableSlot.startTimeOfDay);
            const slotEnd = this.setTime(from, bookableSlot.endTimeOfDay);

            if (bookableSlot.dayOfWeek == from.weekday && slotStart <= from && slotEnd >= to)
            {
                return true;
            }
        }

        return false;
    }

    private setTime(date: DateTime, timeOfDay: string): DateTime
    {
        const duration = Duration.fromISOTime(timeOfDay);
        return date.set({ hour: duration.hours, minute: duration.minutes, second: 0, millisecond: 0 });
    }
}

export interface IBookableSpace
{
    spaceId: string;
    nodeId: number;
    bookingPolicyId: string;
}

export interface IBookableSlot
{
    bookingPolicyId: string;
    dayOfWeek: WeekdayNumbers;
    startTimeOfDay: string;
    endTimeOfDay: string;
}
