import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

type IbssTimePickerProps = TimePickerProps<DateTime>;
class IbssTimePicker extends React.Component<IbssTimePickerProps> {

  constructor(props: any) {
    super(props);
  };

  render(): React.ReactNode {

    return (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <TimePicker
          {...this.props}
          ampm={false}
          slotProps={{
            popper: {
              sx: {
                '& .MuiMultiSectionDigitalClockSection-root': {
                  minWidth: '75px',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    );
  }
};

export default IbssTimePicker;